import {
  ErrorPage,
  Fallback,
  Layout,
  PATH_ANALYSIS,
  PATH_CREATE_PROJECT,
  PATH_CREDIT,
  PATH_END_USER_LICENSE_AGREEMENT,
  PATH_FORGOT_PASSWORD,
  PATH_HOME,
  PATH_MY_ACCOUNT,
  PATH_MY_IMAGES,
  PATH_ONBOARDING,
  PATH_ONBOARDING_EXPIRED,
  PATH_ONBOARDING_RESET_PASSWORD,
  PATH_PRICING,
  PATH_PRICING_PAYMENT,
  PATH_PRICING_PAYMENT_AUTHORIZE,
  PATH_PRICING_PAYMENT_FAIL,
  PATH_PRICING_PAYMENT_SUCCESS,
  PATH_PRIVACY_POLICY,
  PATH_PROJECT_INFO,
  PATH_SIGN_IN,
  PATH_SIGN_UP,
  PATH_TERMS_AND_CONDITIONS,
  PATH_VERIFY,
  PATH_RESET_PASSWORD,
  SignInLayout,
  ForgotPassword,
  ResetPassword,
  SignIn,
  SignUp,
  VerifySignUpCode,
  SignUpOnboarding,
  VerifyResetPasswordCode,
  SignUpEmailExpired,
  PATH_VERIFY_RESET_PASSWORD,
  ResetPasswordOnboarding,
  ResetPasswordEmailExpired,
  PATH_RESET_PASSWORD_EXPIRED,
  PATH_OAUTH_GOOGLE,
  OauthGoogleCode,
  loadCookie,
  deleteCookie,
  SignInAddInfo,
  PATH_SIGN_IN_ADD_INFO,
  PRIVATE_DOMAINS,
  DOMAIN_NAME,
  getToken,
} from "@ovision-gis-frontend/shared"
import { wrapCreateBrowserRouter } from "@sentry/react"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from "react-router-dom"

import Analysis from "./routes/analysis/Analysis"
import CreateProject from "./routes/analysis/CreateProject"
import ProjectContext from "./routes/analysis/ProjectContext"
import ProjectInfo from "./routes/analysis/ProjectInfo"
import GeoAiPack from "./routes/home/GeoAiPack"
import Home, { HomeLoader } from "./routes/home/Home"
import EndUserLicenseAgreement from "./routes/legal/EndUserLicenseAgreement"
import Legal, { LegalLoader } from "./routes/legal/Legal"
import PrivacyPolicy from "./routes/legal/PrivacyPolicy"
import TermsAndConditions from "./routes/legal/TermsAndConditions"
import MyImages from "./routes/my-images/MyImages"
import MyImagesMap from "./routes/my-images/MyImagesMap"
import Payment from "./routes/pricing/payment/Payment"
import PaymentAuthorize from "./routes/pricing/payment/PaymentAuthorize"
import PaymentFail from "./routes/pricing/payment/PaymentFail"
import PaymentLayout from "./routes/pricing/payment/PaymentLayout"
import PaymentSuccess from "./routes/pricing/payment/PaymentSuccess"
import Pricing from "./routes/pricing/Pricing"
import ServiceLayout from "./routes/service-layout/ServiceLayout"
import MyAccount, { MyAccountLoader } from "./routes/user/my-account/MyAccount"
import MyCredit, { CreditLoader } from "./routes/user/my-credit/MyCredit"
import User from "./routes/user/User"

import "./styledAntd.scss"
import "./App.scss"

function Redirect(props: { element: JSX.Element }) {
  if (PRIVATE_DOMAINS.includes(DOMAIN_NAME)) {
    if (getToken()) return <Navigate replace={true} to={PATH_ANALYSIS} />
    else return <Navigate replace={true} to={PATH_SIGN_IN} />
  } else {
    return props.element
  }
}

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter)
const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route element={<Layout />} errorElement={<ErrorPage />}>
      <Route element={<Redirect element={<Home />} />} loader={HomeLoader} path={PATH_HOME}>
        <Route element={<GeoAiPack />} index={true} />
        <Route element={<Pricing />} path={PATH_PRICING} />
        <Route element={<User />}>
          <Route element={<MyAccount />} loader={MyAccountLoader} path={PATH_MY_ACCOUNT} />
          <Route element={<MyCredit />} loader={CreditLoader} path={PATH_CREDIT} />
        </Route>
      </Route>
      <Route element={<Redirect element={<PaymentLayout />} />}>
        <Route element={<Payment />} path={PATH_PRICING_PAYMENT} />
        <Route element={<PaymentAuthorize />} path={PATH_PRICING_PAYMENT_AUTHORIZE} />
        <Route element={<PaymentSuccess />} path={PATH_PRICING_PAYMENT_SUCCESS} />
        <Route element={<PaymentFail />} path={PATH_PRICING_PAYMENT_FAIL} />
      </Route>
      <Route element={<ServiceLayout />}>
        <Route element={<ProjectContext />} path={PATH_ANALYSIS}>
          <Route element={<Analysis />} index={true} />
          <Route element={<CreateProject />} path={PATH_CREATE_PROJECT} />
          <Route element={<ProjectInfo />} path={PATH_PROJECT_INFO} />
        </Route>
        <Route element={<MyImagesMap />} path={PATH_MY_IMAGES}>
          <Route element={<MyImages />} index={true} />
        </Route>
      </Route>
      <Route element={<SignInLayout isAdminWeb={false} />}>
        <Route element={<SignIn isAdmin={false} />} path={PATH_SIGN_IN} />
        <Route element={<OauthGoogleCode />} path={PATH_OAUTH_GOOGLE} />
        <Route element={<SignUp />} path={PATH_SIGN_UP} />
        <Route element={<VerifySignUpCode />} path={PATH_VERIFY} />
        <Route element={<SignUpOnboarding />} path={PATH_ONBOARDING} />
        <Route element={<SignUpEmailExpired />} path={PATH_ONBOARDING_EXPIRED} />
        <Route element={<ForgotPassword />} path={PATH_FORGOT_PASSWORD} />
        <Route element={<ResetPassword />} path={PATH_RESET_PASSWORD} />
        <Route element={<VerifyResetPasswordCode />} path={PATH_VERIFY_RESET_PASSWORD} />
        <Route element={<ResetPasswordOnboarding />} path={PATH_ONBOARDING_RESET_PASSWORD} />
        <Route element={<ResetPasswordEmailExpired />} path={PATH_RESET_PASSWORD_EXPIRED} />
        <Route element={<SignInAddInfo />} path={PATH_SIGN_IN_ADD_INFO} />
      </Route>
      <Route element={<Legal />} loader={LegalLoader}>
        <Route element={<TermsAndConditions />} path={PATH_TERMS_AND_CONDITIONS} />
        <Route element={<PrivacyPolicy />} path={PATH_PRIVACY_POLICY} />
        <Route element={<EndUserLicenseAgreement />} path={PATH_END_USER_LICENSE_AGREEMENT} />
      </Route>
      <Route element={<ErrorPage />} path={"/*"} />
    </Route>,
  ),
)

function App() {
  const { t, ready } = useTranslation()

  useEffect(() => {
    document.documentElement.style.setProperty("--optional", `"${t("textInput.optional") ?? "(Optional)"}"`)
    document.documentElement.style.setProperty("--superx", `"${t("aiPack.superEnhancement.title") ?? "SuperX"}"`)

    //인증 방식 변경 후 쿠키에 access_token이 남아있어 로그인 시 문제가 생기는 것을 방지함.
    if (loadCookie("access_token")) deleteCookie("access_token", `.${window.location.hostname}`)
  }, [t])

  return (
    <div className={"app"}>{ready ? <RouterProvider fallbackElement={<Fallback />} router={router} /> : <Fallback />}</div>
  )
}

export default App
