import {
  CreditCardOutlined,
  SettingsOutlined,
  ConnectionsOutlined,
  MyAccount as MyAccountType,
  PRIVATE_DOMAINS,
  DOMAIN_NAME,
} from "@ovision-gis-frontend/shared"
import { AccountCircleFilled, CloseOutlined, Dropdown, IconButton, TextButton } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import i18next from "i18next"
import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import Pricing from "../pricing/Pricing"
import MyAccount from "../user/my-account/MyAccount"
import MyCredit from "../user/my-credit/MyCredit"
import Connections from "./connections/Connections"
import styles from "./Settings.module.scss"
import SettingsHeader from "./SettingsHeader"

export type SettingsMenu = "MyAccount" | "MyCredit" | "Pricing" | "MySettings" | "Connections"

type SettingMenuContent = {
  menu: SettingsMenu
  icon: React.ReactNode
  text: string
}

type Props = {
  className?: string
  menu?: SettingsMenu
  user?: MyAccountType
  onCloseButtonClick?: React.MouseEventHandler<HTMLElement>
}

function Settings(props: Props) {
  const [menu, setMenu] = useState<SettingsMenu>(props.menu ?? "MyAccount")
  const { t } = useTranslation()

  const settingsMenus: SettingMenuContent[] = useMemo(() => {
    return PRIVATE_DOMAINS.includes(DOMAIN_NAME)
      ? [
          { menu: "MyAccount", icon: <AccountCircleFilled />, text: t("myAccount.title") },
          { menu: "MySettings", icon: <SettingsOutlined />, text: t("mySettings.title") },
          { menu: "Connections", icon: <ConnectionsOutlined />, text: t("connections.title") },
        ]
      : [
          { menu: "MyAccount", icon: <AccountCircleFilled />, text: t("myAccount.title") },
          { menu: "MyCredit", icon: <CreditCardOutlined />, text: t("myCredit.title") },
          { menu: "MySettings", icon: <SettingsOutlined />, text: t("mySettings.title") },
          { menu: "Connections", icon: <ConnectionsOutlined />, text: t("connections.title") },
        ]
  }, [t])

  return (
    <div className={cn(styles.settingsContainer, props.className)}>
      <div className={styles.settings}>
        <div className={styles.sideBar}>
          {settingsMenus.map((settingsMenu) => (
            <TextButton
              className={cn(
                styles.menuButton,
                menu === settingsMenu.menu && styles.active,
                menu === "Pricing" && settingsMenu.menu === "MyCredit" && styles.active,
              )}
              key={settingsMenu.menu}
              type={"secondary"}
              icon={settingsMenu.icon}
              onClick={() => setMenu(settingsMenu.menu)}
            >
              {settingsMenu.text}
            </TextButton>
          ))}
        </div>
        {menu === "MyAccount" && <MyAccount className={styles.menuContent} isSettingsMenu={true} user={props.user} />}
        {menu === "MyCredit" && !PRIVATE_DOMAINS.includes(DOMAIN_NAME) && (
          <MyCredit className={styles.menuContent} isSettingsMenu={true} setMenu={setMenu} user={props.user} />
        )}
        {menu === "Pricing" && !PRIVATE_DOMAINS.includes(DOMAIN_NAME) && (
          <Pricing className={styles.menuContent} isSettingsMenu={true} onBackButtonClick={() => setMenu("MyCredit")} />
        )}
        {menu === "MySettings" && (
          <div className={styles.menuContent}>
            <SettingsHeader title={t("mySettings.title")} />
            <div className={styles.content}>
              <div className={styles.contentItem}>
                <div className={styles.left}>
                  <span className={styles.title}>{t("language.label")}</span>
                  <span className={styles.desc}>{t("language.desc")}</span>
                </div>
                <Dropdown
                  selectClassName={styles.dropdown}
                  size={"large"}
                  type={"single"}
                  itemList={[
                    { text: t("english.label"), value: t("english.label") },
                    { text: t("korean.label"), value: t("korean.label") },
                  ]}
                  value={
                    (i18next.language === "en" ? t("english.label") : i18next.language === "ko" ? t("korean.label") : "") ??
                    ""
                  }
                  onChange={(value) => i18next.changeLanguage(value === t("korean.label") ? "ko" : "en")}
                />
              </div>
            </div>
          </div>
        )}
        {menu === "Connections" && <Connections className={styles.menuContent} />}
        <IconButton
          wrapperClassName={styles.closeButton}
          size={"medium"}
          type={"square"}
          icon={<CloseOutlined />}
          onClick={props.onCloseButtonClick}
        />
      </div>
    </div>
  )
}
export default Settings
