import {
  CreditCardOutlined,
  getDateLabel,
  Project,
  getAiPack,
  getProjectStatus,
  PRIVATE_DOMAINS,
  DOMAIN_NAME,
} from "@ovision-gis-frontend/shared"
import { Chip } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

import ContextMenuPopover, { PopoverContent } from "../service-layout/ContextMenuPopover"
import styles from "./ProjectItem.module.scss"

export type ProjectContextMenu = "PAUSE" | "RESTART" | "CLOSE" | "EDIT" | "DELETE" | ""

type Props = {
  classNames?: string
  data: Project
  active?: boolean
  onProjectClick: (project: Project) => void
  onPauseClick: (project: Project) => void
  onRestartClick: (project: Project) => void
  onCloseClick: (project: Project) => void
  onEditClick: (project: Project) => void
  onDeleteClick: (project: Project) => void
}

function ProjectItem(props: Props) {
  const { t } = useTranslation()

  const getStatusContextMenuList = () => {
    if (props.data.type === "REALTIME") {
      if (props.data.status === "PROCESSING")
        return [...editContextMenuList, ...inProgressStatusContextMenuList, ...deleteContextMenuList]
      else if (props.data.status === "PAUSED")
        return [...editContextMenuList, ...pausedStatusContextMenuList, ...deleteContextMenuList]
      else return [...editContextMenuList, ...deleteContextMenuList]
    } else {
      return [...editContextMenuList, ...deleteContextMenuList]
    }
  }
  const inProgressStatusContextMenuList: PopoverContent[] = useMemo(
    () => [
      {
        text: t("popover.contextMenu.pause"),
        onClick: () => props.onPauseClick(props.data),
      },
      {
        text: t("popover.contextMenu.close"),
        onClick: () => props.onCloseClick(props.data),
      },
    ],
    [props, t],
  )
  const pausedStatusContextMenuList: PopoverContent[] = useMemo(
    () => [
      {
        text: t("popover.contextMenu.restart"),
        onClick: () => props.onRestartClick(props.data),
      },
      {
        text: t("popover.contextMenu.close"),
        onClick: () => props.onCloseClick(props.data),
      },
    ],
    [props, t],
  )
  const editContextMenuList: PopoverContent[] = useMemo(
    () => [{ text: t("popover.contextMenu.edit"), onClick: () => props.onEditClick(props.data) }],
    [props, t],
  )
  const deleteContextMenuList: PopoverContent[] = useMemo(
    () => [{ text: t("popover.contextMenu.delete"), danger: true, onClick: () => props.onDeleteClick(props.data) }],
    [props, t],
  )
  const contextMenuList: PopoverContent[] = useMemo(() => getStatusContextMenuList(), [props, t])

  return (
    <div
      className={cn(styles.projectItem, props.active && styles.active, props.classNames)}
      onClick={() => props.onProjectClick(props.data)}
    >
      <div className={styles.highlight} />
      <div className={styles.iconWrapper}>
        <img className={styles.img} alt={"IMG"} src={getAiPack(props.data.aiPack.type).icon} />
      </div>
      <div className={styles.contents}>
        <div className={styles.titleContainer}>
          <span className={styles.title}>{props.data.name || "-"}</span>
          {/*<span className={styles.statusIcon}>{analysisStatus}</span>*/}
        </div>
        <Chip className={styles.chip} size={"small"} color={"grey"} label={getAiPack(props.data.aiPack.type).label} />
        <div className={styles.descContainer}>
          <span
            className={cn(
              props.data.type === "REALTIME" && styles.realtime,
              props.data.status === "PROCESSING" && styles.inProgress,
              props.data.status === "PAUSED" && styles.paused,
            )}
          >
            {(props.data.type === "REALTIME" ? getProjectStatus(props.data.status) : getDateLabel(props.data.createdTime)) ||
              "-"}
          </span>
          {!PRIVATE_DOMAINS.includes(DOMAIN_NAME) && (
            <>
              <span>{` •`}</span>
              <CreditCardOutlined />
              <span>{`${props.data.creditAmount || "-"}`}</span>
            </>
          )}
        </div>
        <ContextMenuPopover
          className={styles.contextMenu}
          containerClassName={styles.contextPopoverContainer}
          positionClassName={styles.positionClassName}
          button={{ className: styles.contextMenuBtn }}
          contents={contextMenuList}
        />
      </div>
    </div>
  )
}
export default ProjectItem
