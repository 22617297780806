import {
  AiPackInfos,
  AiPackType,
  getAiPacks,
  getScene,
  getSyncStorages,
  InferenceRequestType,
  initAiPackInfos,
  initScene,
  initSyncStorage,
  isError,
  LayerInfoListType,
  Loading,
  PATH_ANALYSIS,
  PATH_BACK,
  postPreviewCredit,
  postInferences,
  postProjects,
  ProjectRequestType,
  ProviderType,
  Scene,
  SyncStorage,
  isInitialScene,
  getMyAccount,
  initMyAccount,
  MyAccount,
  getMyPermissions,
  isInitialSyncStorage,
  getAiPack,
  getValidProjectName,
  AREA_AOI_LIMIT,
  generateVectorSourceFromWkb,
  getFormattedArea,
  SourceType,
  Provider,
  initProvider,
  CloudOutlined,
  SatelliteOutlined,
  PhotoFrameOutlined,
  DOMAIN_NAME,
  PRIVATE_DOMAINS,
} from "@ovision-gis-frontend/shared"
import MapboxImg from "@ovision-gis-frontend/shared/src/asset/images/Mapbox.png"
import { captureException } from "@sentry/react"
import {
  ArrowBackOutlined,
  Button,
  IconButton,
  InfoOutlined,
  InputField,
  NavigateNextOutlined,
  Switch,
  Toast,
  Tooltip,
} from "@SIAnalytics/ovision-design-system"
import { ItemOption } from "@SIAnalytics/ovision-design-system/build/src/component/data-entry/context-menu/ContextMenu"
import cn from "classnames"
import { Coordinate } from "ol/coordinate"
import { getBottomRight, getTopLeft } from "ol/extent"
import { WKB } from "ol/format"
import { Polygon } from "ol/geom"
import { isEmpty } from "ol/obj"
import { toLonLat } from "ol/proj"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { getCloudImg, getCloudName } from "../../common/cloudUtil"
import MyImages from "../my-images/MyImages"
import Panel from "../service-layout/Panel"
import PanelTitle from "../service-layout/PanelTitle"
import styles from "./CreateProject.module.scss"
import CreateProjectMap from "./CreateProjectMap"
import FileItem from "./FileItem"
import HoverInfoPopover from "./HoverInfoPopover"
import { useProjectOutletContext } from "./ProjectContext"
import SelectBucketModal, { SelectBucketType } from "./SelectBucketModal"
import SourceItem from "./SourceItem"

type PanelType = "PROJECT_NAME" | "ANALYSIS_TYPE" | "SOURCE" | "CREDIT_BOUNDARY"
type AiPackContent = { name: string; aiPack: AiPackType[] }
type ProviderContent = {
  name: string
  type: ProviderType
  src: string
  desc: string
}
export type SourceTabType = SourceType
export type WaybackLayerType = {
  title: string
  identifier: string
}
export const initWaybackLayer = (): WaybackLayerType => {
  return { title: "", identifier: "" }
}

function CreateProject() {
  const { setIsSettingsVisible, setSettingsMenu } = useProjectOutletContext()
  const { view } = useProjectOutletContext()

  const [user, setUser] = useState<MyAccount>(initMyAccount)
  const [aiPackInfos, setAiPackInfos] = useState<AiPackInfos>(initAiPackInfos)
  const [projectName, setProjectName] = useState<string>("")
  const [aiPack, setAiPack] = useState<AiPackType>("")
  const [hoveredAiPack, setHoveredAiPack] = useState<AiPackType>("")
  const [isInfoHoverVisible, setIsInfoHoverVisible] = useState<boolean>(false)

  const [isMyImagesPanelVisible, setIsMyImagesPanelVisible] = useState<boolean>(false)
  const [selectedBucket, setSelectedBucket] = useState<SyncStorage>(initSyncStorage)
  const [selectBucketModalType, setSelectBucketModalType] = useState<SelectBucketType>("SOURCE")
  const [isSelectBucketModalVisible, setIsSelectBucketModalVisible] = useState<boolean>(false)
  const [isAutoExportResultsActive, setIsAutoExportResultsActive] = useState<boolean>(false)
  const [exportBucket, setExportBucket] = useState<SyncStorage>(initSyncStorage)
  const requiredCredit = useRef<number>(0)
  const [permissions, setPermissions] = useState<string[]>([])

  const [providerUrl, setProviderUrl] = useState<Provider>(initProvider)
  const [source, setSource] = useState<SourceTabType>("")
  const [provider, setProvider] = useState<ProviderType>("")
  const [waybackDropdownItems, setWaybackDropdownItems] = useState<ItemOption[]>([])
  const [waybackLayers, setWaybackLayers] = useState<WaybackLayerType[]>([])
  const [waybackLayer, setWaybackLayer] = useState<WaybackLayerType>(initWaybackLayer)
  const [selectedLocalImage, setSelectedLocalImage] = useState<Scene>(initScene)

  const aoiArea = useRef<number>(0)
  const [aoiPolygon, setAoiPolygon] = useState<Polygon | null>(null)
  const [center, setCenter] = useState<number[]>([])

  const [isSyncStorageFetching, setIsSyncStorageFetching] = useState<boolean>(true)
  const [isAiPackFetching, setIsAiPackFetching] = useState<boolean>(true)
  const [isPreviewLoading, setIsPreviewLoading] = useState<boolean>(false)
  const [isInferenceRequesting, setIsInferenceRequesting] = useState<boolean>(false)

  const [currentPanel, setCurrentPanel] = useState<PanelType[]>(["PROJECT_NAME"])

  const navigate = useNavigate()
  const { t } = useTranslation()

  const imageImprovementAiPacks: AiPackContent = {
    name: t("service.imageImprovement.title"),
    aiPack: ["SuperEnhancement"],
  }
  const objectDetectionAiPacks: AiPackContent = {
    name: t("service.objectDetection.title"),
    aiPack: ["AircraftDetection", "VesselDetection", "VehicleDetection"],
  }
  const infrastructureAiPacks: AiPackContent = {
    name: t("service.infrastructure.title"),
    aiPack: [/*"LandCoverSegmentation"*/ "BuildingSegmentation", "RoadSegmentation"],
  }

  const aiPackContents: AiPackContent[] = useMemo(() => {
    return PRIVATE_DOMAINS.includes(DOMAIN_NAME)
      ? [imageImprovementAiPacks]
      : [imageImprovementAiPacks, objectDetectionAiPacks, infrastructureAiPacks]
  }, [imageImprovementAiPacks, objectDetectionAiPacks, infrastructureAiPacks])

  const providerList: ProviderContent[] = [
    {
      name: t("analysis.source.mapbox.label"),
      type: "MAPBOX",
      src: MapboxImg,
      desc: t("analysis.source.mapbox.desc"),
    },
    /*{ @NOTE: Wayback Dropped
        name: t("analysis.source.wayback.label"),
        type: "WAYBACK",
        src: WaybackImg,
        desc: t("analysis.source.wayback.desc"),
      },*/
  ]

  const isCreditRechargeInfoVisible = requiredCredit.current - user.creditInfo.remainCredit > 0
  const isAoiOverTheLimit = useMemo(
    () => source === "PROVIDERS" && aoiArea.current > (parseInt(t("aiPack.aoi.limit")) || AREA_AOI_LIMIT) / 1e6,
    [aoiArea.current, source, t],
  )
  const isRunInferenceDisabled = useMemo(
    () =>
      projectName === "" ||
      aiPack === "" ||
      !source ||
      (source === "CLOUD" && isInitialSyncStorage(selectedBucket)) ||
      (source === "PROVIDERS" && (aiPack === "SuperEnhancement" || !provider || !aoiPolygon || isAoiOverTheLimit)) ||
      (source === "MY_IMAGES" && (isInitialScene(selectedLocalImage) || isAoiOverTheLimit)) ||
      (isAutoExportResultsActive && isInitialSyncStorage(exportBucket)) ||
      isCreditRechargeInfoVisible ||
      isPreviewLoading,
    [
      projectName,
      aiPack,
      source,
      selectedBucket,
      provider,
      aoiPolygon,
      isAoiOverTheLimit,
      selectedLocalImage,
      isAutoExportResultsActive,
      exportBucket,
      isCreditRechargeInfoVisible,
      isPreviewLoading,
    ],
  )

  const aiPackZoomLevel: { [key: string]: number } = {
    "1001": 17,
    "1002": 17,
    "1004": 16,
    "1005": 16,
    "1006": 19,
    "1007": 19,
    "1013": 16, // SuperX
    "1016": 17, // Land Cover Segmentation
  }
  const getLayerInfoList = () => {
    let layerInfoList: LayerInfoListType | undefined = undefined
    if (source === "MY_IMAGES") layerInfoList = [{ serviceName: "MY_IMAGES", layer: selectedLocalImage?.id, matrixset: "" }]
    else if (provider === "MAPBOX")
      layerInfoList = [{ serviceName: "MAPBOX", layer: "clb5za3o3000d15lb7bt7nayt", matrixset: "GoogleMapsCompatible" }]
    else if (provider === "WAYBACK")
      layerInfoList = [{ serviceName: "WAYBACK", layer: waybackLayer.identifier, matrixset: "GoogleMapsCompatible" }]

    return layerInfoList
  }

  useEffect(() => {
    const setUserAsync = async () => {
      try {
        const user = await getMyAccount()
        const permissions = await getMyPermissions()
        setPermissions(permissions)
        setUser(user)
      } catch (e) {
        captureException(e)
      }
    }
    const setAiPacksAsync = async () => {
      try {
        const aiPacks = await getAiPacks()
        if (!isError(aiPacks)) setAiPackInfos(aiPacks)
      } catch (e) {
        captureException(e)
      } finally {
        setIsAiPackFetching(false)
      }
    }
    const setProviderUrlAsync = async () => {
      try {
        const _providerUrl = await getScene()
        if (!isError(_providerUrl)) setProviderUrl(_providerUrl)
      } catch (e) {
        captureException(e)
      }
    }

    void setUserAsync()
    void setAiPacksAsync()
    void setProviderUrlAsync()

    return () => {
      setSelectedLocalImage(initScene)
      setSelectedBucket(initSyncStorage)
      setSelectBucketModalType("SOURCE")
      setAiPack("")
      setSource("")
      setProvider("")
      setWaybackLayer(initWaybackLayer)
      setCurrentPanel(["PROJECT_NAME"])
    }
  }, [])

  useEffect(() => {
    const setSyncStoragesAsync = async () => {
      try {
        const _syncStorages = await getSyncStorages()
        if (!isError(_syncStorages)) {
          const _exportBucket = _syncStorages.syncStorages.find((syncStorage) => syncStorage.status === "SUCCESS")
          if (_exportBucket) {
            setExportBucket(_exportBucket)
            setIsAutoExportResultsActive(true)
          } else {
            setExportBucket(initSyncStorage)
            setIsAutoExportResultsActive(false)
          }
        }
      } catch (error) {
        captureException(error)
      } finally {
        setIsSyncStorageFetching(false)
      }
    }
    void setSyncStoragesAsync()
  }, [selectedBucket])

  useEffect(() => {
    const canNotCalculateCredit =
      !source || (source === "PROVIDERS" && !provider) || (source === "MY_IMAGES" && isInitialScene(selectedLocalImage))

    const setRequiredCreditAsync = async () => {
      const aoi = aoiPolygon?.clone().transform("EPSG:3857", "EPSG:4326")
      const _aiPackInfo = aiPackInfos.aiPackInfoList.find((_aiPackInfo) => aiPack === _aiPackInfo.type)

      if (canNotCalculateCredit || !_aiPackInfo) return

      const layerInfoList = getLayerInfoList()
      let tl: Coordinate = []
      let br: Coordinate = []

      if (aoi && aiPack !== "SuperEnhancement") {
        const extent = aoi.getExtent()
        tl = getTopLeft(extent)
        br = getBottomRight(extent)
      } else if (source === "MY_IMAGES" && selectedLocalImage) {
        const extent = generateVectorSourceFromWkb(selectedLocalImage.boundary).getExtent()
        tl = toLonLat(getTopLeft(extent))
        br = toLonLat(getBottomRight(extent))
      } else {
        requiredCredit.current = 0
        aoiArea.current = 0
        return
      }

      const data: InferenceRequestType = {
        // @NOTE: jobName이 빈 값일 경우 400 반환, 해당 API의 경우 크레딧 계산하는 API로 jobName의 영향을 받지 않아 임의 설정
        jobName: "preview",
        aipackId: parseInt(_aiPackInfo.id),
        tlLon: tl[0],
        tlLat: tl[1],
        brLon: br[0],
        brLat: br[1],
        layerInfoList: layerInfoList,
        zoom: aiPackZoomLevel[_aiPackInfo.id] ?? 17,
      }
      setIsPreviewLoading(true)
      try {
        const { paidCredit, areaSize } = await postPreviewCredit(data)
        requiredCredit.current = paidCredit
        aoiArea.current = areaSize
      } catch (e) {
        captureException(e)
      } finally {
        setIsPreviewLoading(false)
      }
    }

    void setRequiredCreditAsync()

    return () => {
      requiredCredit.current = 0
    }
  }, [aoiPolygon, aiPack, selectedLocalImage, source])

  useEffect(() => {
    if (source !== "MY_IMAGES") setIsMyImagesPanelVisible(false)
    if (aiPack === "SuperEnhancement" && source === "PROVIDERS") setSource("")
  }, [t, source, aiPack])

  useEffect(() => {
    if (
      (source === "MY_IMAGES" && isInitialScene(selectedLocalImage) && !isMyImagesPanelVisible) ||
      (source === "CLOUD" && isInitialSyncStorage(selectedBucket) && !isSelectBucketModalVisible)
    )
      setSource("")
  }, [isMyImagesPanelVisible, isSelectBucketModalVisible, selectedBucket, selectedLocalImage, source])

  useEffect(() => {
    setWaybackDropdownItems(waybackLayers.map((layer) => ({ text: layer.title, value: layer.title })))
    if (waybackLayers.length > 0 && waybackLayer.title === "" && waybackLayer.identifier === "")
      setWaybackLayer(waybackLayers[0])
  }, [waybackLayers])

  const handleRunInferenceButtonClick = () => {
    const setRemainCreditAsync = async (aoi?: Polygon) => {
      const _aiPackInfo = aiPackInfos.aiPackInfoList.find((_aiPackInfo) => aiPack === _aiPackInfo.type)
      if (!_aiPackInfo) {
        Toast({ message: t("toast.createProject.aiPack.error"), type: "error" })
        setIsInferenceRequesting(false)
        return
      }

      if (source === "CLOUD") {
        let wkbAoi = null
        if (aoi) {
          const wkbFormat = new WKB()
          wkbAoi = wkbFormat.writeGeometry(aoi)
        }
        if (typeof wkbAoi !== "string" && wkbAoi !== null) return
        if (!selectedBucket.cloudType) return
        const _sourceType = selectedBucket.cloudType === "SIA_CLOUD" ? "GCP" : selectedBucket.cloudType

        const data: ProjectRequestType = {
          name: projectName,
          type: "REALTIME",
          aoi: _aiPackInfo.id === "1013" ? null : wkbAoi, // SuperX인 경우 null
          aiPackId: parseInt(_aiPackInfo.id),
          source: { id: selectedBucket.id, paths: [selectedBucket.name], type: _sourceType },
          destination: isAutoExportResultsActive ? { id: exportBucket.id, path: exportBucket.name } : undefined,
        }

        try {
          const _project = await postProjects(data)
          if (!isError(_project)) {
            navigate(PATH_ANALYSIS, { replace: true })
            Toast({ message: t("toast.createProject.projectRequested.success"), type: "success" })
          }
        } catch (e) {
          captureException(e)
        } finally {
          setIsInferenceRequesting(false)
        }
      } else {
        const layerInfoList = getLayerInfoList()

        let tl: Coordinate = []
        let br: Coordinate = []
        if (aoi) {
          const extent = aoi.getExtent()
          tl = getTopLeft(extent)
          br = getBottomRight(extent)
        }

        const data: InferenceRequestType = {
          jobName: projectName,
          aipackId: parseInt(_aiPackInfo.id),
          tlLon: tl[0],
          tlLat: tl[1],
          brLon: br[0],
          brLat: br[1],
          layerInfoList: layerInfoList,
          zoom: aiPackZoomLevel[_aiPackInfo.id] ?? 17,
          destination: isAutoExportResultsActive ? { id: exportBucket.id, path: exportBucket.name } : undefined,
        }

        try {
          const _inferences = await postInferences(data)
          if (!isError(_inferences)) {
            navigate(PATH_ANALYSIS, { replace: true })
            Toast({ message: t("toast.createProject.projectRequested.success"), type: "success" })
          }
        } catch (e) {
          captureException(e)
        } finally {
          setIsInferenceRequesting(false)
        }
      }
    }

    if (isRunInferenceDisabled) return
    setIsInferenceRequesting(true)
    void setRemainCreditAsync(aoiPolygon?.clone().transform("EPSG:3857", "EPSG:4326") as Polygon)
  }

  const handleImageClick = (image: Scene) => {
    setSelectedLocalImage(image)
    setIsMyImagesPanelVisible(false)
  }

  const handleConnectButtonClick = () => {
    setSettingsMenu("Connections")
    setIsSettingsVisible(true)
  }
  const handleCreditInfoBoxClick = () => {
    setSettingsMenu("Pricing")
    setIsSettingsVisible(true)
  }

  return (
    <div className={styles.createProject}>
      <Panel className={styles.panel}>
        <div
          className={cn(
            styles.topContainer,
            currentPanel.includes("CREDIT_BOUNDARY") && styles.isCreditBoundaryVisible,
            isCreditRechargeInfoVisible && styles.creditRechargeInfoWrapperVisible,
            PRIVATE_DOMAINS.includes(DOMAIN_NAME) && styles.isPrivateDomains,
          )}
        >
          <div className={styles.titleContainer}>
            <IconButton
              wrapperClassName={styles.iconBtn}
              size={"small"}
              type={"square"}
              icon={<ArrowBackOutlined />}
              onClick={() => navigate(PATH_BACK)}
            />
            <PanelTitle className={styles.title} label={t("createProject.title")} />
          </div>
          <div className={styles.formContainer}>
            <div className={styles.form}>
              <span className={styles.fieldLabel}>{t("createProject.projectName.label")}</span>
              <InputField
                placeholder={
                  t("textInput.placeholder", { target: t("createProject.projectName.label").toLowerCase() }) ?? ""
                }
                autoFocus={true}
                maxLength={50}
                value={projectName}
                onBlur={(e) => {
                  if (projectName.length > 0) setCurrentPanel((prev) => [...prev, "ANALYSIS_TYPE"])
                }}
                onChange={(e) => setProjectName(getValidProjectName(e.target.value))}
                onClearButtonClick={() => setProjectName("")}
                onKeyPress={(e) => {
                  if (/[`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/.test(e.key)) e.preventDefault()
                  if (e.key == "Enter" && projectName.length > 0) setCurrentPanel((prev) => [...prev, "ANALYSIS_TYPE"])
                }}
              />
            </div>

            {currentPanel.includes("ANALYSIS_TYPE") && (
              <>
                <div className={styles.divider} />

                <div className={styles.form}>
                  <HoverInfoPopover
                    aiPack={hoveredAiPack}
                    isVisible={isInfoHoverVisible}
                    setIsVisible={setIsInfoHoverVisible}
                  />
                  <span className={styles.fieldLabel}>{t("createProject.analysisType.label")}</span>
                  <div className={styles.analysisTypeContainer}>
                    {aiPackContents.map((_aiPackContent) => (
                      <div className={styles.serviceContainer} key={_aiPackContent.name}>
                        <p className={styles.label}>{_aiPackContent.name}</p>
                        <div className={styles.buttonContainer}>
                          {_aiPackContent.aiPack.map((_aiPack) => (
                            <Tooltip
                              key={_aiPack}
                              size={"large"}
                              title={
                                _aiPack === "SuperEnhancement" && source === "PROVIDERS"
                                  ? t("tooltip.createProject.superx.disabled")
                                  : ""
                              }
                              placement={"topLeft"}
                              point={true}
                            >
                              <button
                                className={cn(aiPack === _aiPack && styles.active)}
                                disabled={_aiPack === "SuperEnhancement" && source === "PROVIDERS"}
                                onClick={(e) => {
                                  setAiPack((prev) => (prev === _aiPack ? "" : _aiPack))
                                  setCurrentPanel((prev) => [...prev, "SOURCE"])
                                }}
                                onMouseDown={(e) => {
                                  setHoveredAiPack(_aiPack)
                                  setIsInfoHoverVisible(true)
                                }}
                                onMouseEnter={(e) => {
                                  setHoveredAiPack(_aiPack)
                                  setIsInfoHoverVisible(true)
                                }}
                                onMouseLeave={(e) => {
                                  setHoveredAiPack("")
                                  setIsInfoHoverVisible(false)
                                }}
                              >
                                <img src={getAiPack(_aiPack).icon} />
                                <p>{getAiPack(_aiPack).label.split(" ")[0]}</p>
                              </button>
                            </Tooltip>
                          ))}
                        </div>
                        {_aiPackContent.aiPack.includes(aiPack) && (
                          <section className={styles.infoBox}>
                            <InfoOutlined />
                            <p>{getAiPack(aiPack).desc}</p>
                          </section>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
            {currentPanel.includes("SOURCE") && (
              <>
                <div className={styles.divider} />

                <div className={styles.form}>
                  <span className={styles.fieldLabel}>{t("createProject.source.label")}</span>
                  <div className={cn(styles.buttonContainer, styles.grid)}>
                    <button
                      className={cn(source === "MY_IMAGES" && styles.active)}
                      onClick={(e) => {
                        setSource("MY_IMAGES")
                        setIsMyImagesPanelVisible(!isMyImagesPanelVisible)
                        setCurrentPanel((prev) => [...prev, "CREDIT_BOUNDARY"])
                      }}
                    >
                      <PhotoFrameOutlined />
                      <p>{t("myImages.title")}</p>
                    </button>
                    <button
                      className={cn(source === "CLOUD" && styles.active)}
                      onClick={(e) => {
                        setSource("CLOUD")
                        setSelectBucketModalType("SOURCE")
                        setIsSelectBucketModalVisible(true)
                        setCurrentPanel((prev) => [...prev, "CREDIT_BOUNDARY"])
                      }}
                    >
                      <CloudOutlined />
                      <p>{t("cloud.label")}</p>
                    </button>
                    {!PRIVATE_DOMAINS.includes(DOMAIN_NAME) && (
                      <Tooltip
                        className={styles.tooltip}
                        size={"large"}
                        title={aiPack === "SuperEnhancement" ? t("tooltip.createProject.mapbox.disabled") : ""}
                        placement={"bottomLeft"}
                        point={true}
                      >
                        <button
                          className={cn(source === "PROVIDERS" && styles.active)}
                          disabled={aiPack === "SuperEnhancement"}
                          onClick={(e) => {
                            setSource((prev) => (prev === "PROVIDERS" ? "" : "PROVIDERS"))
                            setProvider((prev) => (prev === "MAPBOX" ? "" : "MAPBOX"))
                            setCurrentPanel((prev) => [...prev, "CREDIT_BOUNDARY"])
                          }}
                        >
                          <SatelliteOutlined />
                          <p>{t("analysis.source.mapbox.label")}</p>
                        </button>
                      </Tooltip>
                    )}
                  </div>

                  {source === "CLOUD" && !isInitialSyncStorage(selectedBucket) && (
                    <SourceItem
                      className={styles.sourceItem}
                      rightToolClassName={styles.rightTool}
                      title={selectedBucket.name}
                      rightTool={
                        <Button
                          size={"xs"}
                          type={"outlined"}
                          onClick={() => {
                            setSelectBucketModalType("SOURCE")
                            setIsSelectBucketModalVisible(true)
                          }}
                        >
                          {t("button.edit")}
                        </Button>
                      }
                      description={getCloudName(selectedBucket.cloudType).title}
                      img={getCloudImg(selectedBucket.cloudType)}
                    />
                  )}
                  {source === "MY_IMAGES" && !isInitialScene(selectedLocalImage) && (
                    <FileItem
                      className={styles.sourceItem}
                      files={[
                        {
                          id: selectedLocalImage.id,
                          desc: t("analysis.source.myImages.label") ?? "",
                          contents: [
                            {
                              name: selectedLocalImage.name,
                              contentType: "Image",
                              onDeleteClick: () => setSelectedLocalImage(initScene),
                            },
                          ],
                        },
                      ]}
                    />
                  )}
                </div>

                <div className={styles.divider} />

                <div className={styles.form}>
                  <div className={styles.fieldLabelContainer}>
                    <span className={styles.fieldLabel}>
                      {t("createProject.autoExportResults.label")}
                      <Tooltip size={"large"} title={t("tooltip.createProject.autoExportResults")} point={true}>
                        <InfoOutlined />
                      </Tooltip>
                    </span>
                    <div className={styles.switchContainer}>
                      <Switch
                        className={styles.switch}
                        type={"only"}
                        active={isAutoExportResultsActive}
                        disabled={isInitialSyncStorage(exportBucket)}
                        onClick={() => setIsAutoExportResultsActive(!isAutoExportResultsActive)}
                      />
                      {isSyncStorageFetching && <Loading className={styles.loading} size={"xs"} />}
                    </div>
                  </div>
                  {isAutoExportResultsActive && (
                    <SourceItem
                      className={styles.sourceItem}
                      rightToolClassName={styles.rightTool}
                      title={exportBucket.name}
                      rightTool={
                        <Button
                          size={"xs"}
                          type={"outlined"}
                          onClick={() => {
                            setSelectBucketModalType("EXPORT")
                            setIsSelectBucketModalVisible(true)
                          }}
                        >
                          {t("button.edit")}
                        </Button>
                      }
                      description={getCloudName(exportBucket.cloudType).title}
                      img={getCloudImg(exportBucket.cloudType)}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>

        <div className={styles.bottomContainer}>
          {currentPanel.includes("CREDIT_BOUNDARY") && (
            <>
              {isCreditRechargeInfoVisible && (
                <button className={styles.creditInfoBox} onClick={handleCreditInfoBoxClick}>
                  <div className={styles.left}>
                    <InfoOutlined />
                    <div className={styles.content}>
                      {t("createProject.creditRechargeGuide.label", {
                        credit: requiredCredit.current - user.creditInfo.remainCredit,
                      })}
                    </div>
                  </div>
                  <div className={styles.right}>
                    <NavigateNextOutlined />
                  </div>
                </button>
              )}
              <div className={styles.creditContainer}>
                <div className={styles.content}>
                  <span>{t("createProject.area.label")}</span>
                  <span className={cn(isAoiOverTheLimit && styles.danger)}>
                    {getFormattedArea(isEmpty(aoiPolygon) && source === "PROVIDERS" ? 0 : aoiArea.current)}
                  </span>
                </div>
                {!PRIVATE_DOMAINS.includes(DOMAIN_NAME) && (
                  <>
                    <div className={styles.content}>
                      <span>{t("createProject.requiredCredit.label")}</span>
                      <span className={styles.info}>
                        {(isEmpty(aoiPolygon) && source === "PROVIDERS" ? 0 : requiredCredit.current) || "-"}
                      </span>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.content}>
                      <span>{t("createProject.myCredit.label")}</span>
                      <span>{user.creditInfo.remainCredit}</span>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
          <Button
            className={styles.ctaButton}
            size={"xl"}
            type={"cta"}
            disabled={isRunInferenceDisabled}
            loading={isInferenceRequesting || isPreviewLoading}
            onClick={handleRunInferenceButtonClick}
          >
            {t("button.createProject")}
          </Button>
        </div>
      </Panel>

      <CreateProjectMap
        aiPack={aiPack}
        aoiArea={aoiArea}
        aoiPolygon={aoiPolygon}
        center={center}
        provider={provider}
        providerUrl={providerUrl}
        selectedLocalImage={selectedLocalImage}
        setAoiPolygon={setAoiPolygon}
        setCenter={setCenter}
        setProvider={setProvider}
        setProviderUrl={setProviderUrl}
        setSelectedLocalImage={setSelectedLocalImage}
        setWaybackLayers={setWaybackLayers}
        source={source}
        view={view}
        waybackLayer={waybackLayer}
      />
      {isMyImagesPanelVisible && (
        <MyImages
          className={styles.myImagesPanel}
          activeImage={selectedLocalImage}
          isPanel={true}
          myImagesPanelVisible={isMyImagesPanelVisible}
          onCloseButtonClick={() => setIsMyImagesPanelVisible(!isMyImagesPanelVisible)}
          onImageClick={handleImageClick}
        />
      )}
      {isSelectBucketModalVisible && (
        <SelectBucketModal
          type={selectBucketModalType}
          closeModal={() => setIsSelectBucketModalVisible(false)}
          onNewConnectButtonClick={handleConnectButtonClick}
          onTableRowClick={(record) =>
            selectBucketModalType === "SOURCE" ? setSelectedBucket(record) : setExportBucket(record)
          }
        />
      )}
    </div>
  )
}
export default CreateProject
