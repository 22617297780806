import { DOMAIN_NAME, getMyAccount, initMyAccount, LoaderData, PRIVATE_DOMAINS } from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import cn from "classnames"
import React, { useEffect } from "react"
import { Outlet, useLoaderData, useLocation } from "react-router-dom"

import FootNavigationBar from "../home/FootNavigationBar"
import GlobalNavigationBar from "../home/GlobalNavigationBar"
import styles from "./Legal.module.scss"
import LegalSideNavigationBar from "./LegalSideNavigationBar"

export const LegalLoader = async () => {
  let user = initMyAccount()
  if (PRIVATE_DOMAINS.includes(DOMAIN_NAME)) {
    try {
      user = await getMyAccount()
    } catch (e) {
      captureException(e)
    }
  }
  return user
}
function Legal() {
  const user = useLoaderData() as LoaderData<typeof LegalLoader>
  const location = useLocation()

  useEffect(() => {
    document.getElementById("legal")?.scrollIntoView({ behavior: "auto" })
  }, [location])

  return (
    <main>
      {!PRIVATE_DOMAINS.includes(DOMAIN_NAME) && <GlobalNavigationBar user={user} />}
      <div className={cn(styles.scrollable, PRIVATE_DOMAINS.includes(DOMAIN_NAME) && styles.isPrivateDomains)}>
        <div className={styles.protectedLayout} id={"legal"}>
          <LegalSideNavigationBar />
          <Outlet />
        </div>
        {!PRIVATE_DOMAINS.includes(DOMAIN_NAME) && <FootNavigationBar />}
      </div>
    </main>
  )
}
export default Legal
